import { useStoreUsers } from "@coworker/app/src/hooks/useStoreContacts";
import { Trans } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import peopleIcon from "@ingka/ssr-icon/paths/people";
import styles from "./task-card.module.css";

export const Creator = ({ task }: { task: Task }) => {
  const users = useStoreUsers();
  const creator_name = React.useMemo(() => {
    const user = users.find(
      (user: any) =>
        user.fixa_uid === task.creator_id || user.uid === task.creator_id
    );
    return user ? (
      `${user.given_name} ${user.surname}`
    ) : (
      <Trans>unknownUserString</Trans>
    );
  }, [users, task.creator_id]);

  return (
    <div className={styles["creator-wrapper"]}>
      <SSRIcon
        className={styles["assignee-icon"] as string}
        paths={peopleIcon}
      />
      {creator_name}
    </div>
  );
};
