import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import RectangleIcon from "@ingka/ssr-icon/paths/rectangle-horizontal";
import RectangleDoubleIcon from "@ingka/ssr-icon/paths/rectangle-horizontal-double";
import RectangleTrippleIcon from "@ingka/ssr-icon/paths/rectangle-horizontal-thin-tripple";
import { CARD_MODE } from "./constants";
import { CardMode } from "./types";

interface CardModeSwitcherProps {
  cardMode: CardMode;
  setCardMode: (mode: CardMode) => void;
}

export const CardModeSwitcher = ({
  cardMode,
  setCardMode,
}: CardModeSwitcherProps) => {
  const handleModeChange = () => {
    let newMode: CardMode;

    if (cardMode === CARD_MODE.DEFAULT) {
      newMode = CARD_MODE.COMPLEX;
    } else if (cardMode === CARD_MODE.COMPLEX) {
      newMode = CARD_MODE.SIMPLE;
    } else {
      newMode = CARD_MODE.DEFAULT;
    }

    localStorage.setItem("cardMode", newMode);
    setCardMode(newMode);
  };

  return (
    <SSRIcon
      paths={
        cardMode === CARD_MODE.DEFAULT
          ? RectangleDoubleIcon
          : cardMode === CARD_MODE.SIMPLE
          ? RectangleIcon
          : RectangleTrippleIcon
      }
      onClick={handleModeChange}
    />
  );
};
