import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import imageIcon from "@ingka/ssr-icon/paths/image";
import commentIcon from "@ingka/ssr-icon/paths/speech-bubble";
import peopleIcon from "@ingka/ssr-icon/paths/people";
import crossIcon from "@ingka/ssr-icon/paths/cross-circle";
import arrowIcon from "@ingka/ssr-icon/paths/arrow-pair-squarepath";
import { getTaskInfo } from "../../helpers/getTaskInfo";
import { Trans, useTranslation } from "@coworker/locales";
import { useLocation } from "react-router-dom";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { getClosedTaskReason } from "../../helpers/getClosedTaskReason";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";
import { getRelatedTaskInfo } from "../../helpers/getRelatedTaskInfo";
import { useFetchedBasicProduct } from "@coworker/app/src/hooks/useBasicProductsMap";
import { Task } from "@coworker/types/lib/tasks/base";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import { useAllImages } from "@coworker/app/src/hooks/useTaskImages";
import { useTaskNotes } from "@coworker/app/src/hooks/useTaskNotes";
import useFormatter from "@coworker/app/src/hooks/useFormatter";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import useFormatScheduled from "@coworker/app/src/hooks/useFormatScheduled";
import { useWatchedTask } from "@coworker/app/src/hooks/useWatchedTasks";
import { CustomTask } from "@coworker/types/lib/tasks/custom";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import { isOverdue } from "@coworker/app/src/helpers/taskHelper";
import { CardMode } from "./types";
import { Creator } from "./Creator";
import { CreatedAtDate } from "./CreatedAtDate";

interface TaskCardProps {
  task: Task;
  multiSelect?: boolean;
  isSwiping?: boolean;
  type?: string;
  taskModal?: boolean;
  cardMode?: CardMode;
}

export const TaskCard = ({
  task,
  multiSelect,
  isSwiping,
  type,
  taskModal = false,
  cardMode = "default",
}: TaskCardProps) => {
  const { t } = useTranslation();
  const { toggleModal } = React.useContext(ModalContext);
  const location = useLocation();
  const isRelatedTasks = location.pathname.includes("related");

  const addonTask = task as AddonTask;
  const { productUnit } = useFormatter();
  const productUnitTitle = productUnit(
    addonTask.requested_type,
    addonTask.actual_requested_quantity
  );
  const taskInfo = !isRelatedTasks
    ? getTaskInfo(task, t as (key?: string) => string, productUnitTitle)
    : getRelatedTaskInfo(task, t as (key?: string) => string, productUnitTitle);
  const { push } = useWorkspacesAction();

  const isTaskCompleted = task.state === taskStateOptions.COMPLETED;
  const isTaskClosed = task.state === taskStateOptions.CLOSED;

  const { checkIfSelected, toggle } = useMultiSelectForTab(type) as any;
  const toggleThis = () => toggle(task.id);
  const isSelected = checkIfSelected?.(task.id);

  const fullId = `${addonTask.product_article_type}${addonTask.product_article_id}`;
  const { basicProduct } = useFetchedBasicProduct(fullId);
  const productDescripion = basicProduct?.descriptivesWithoutKindAndColor || "";
  const taskImages = useAllImages(task.id ?? "");
  const taskNotes = useTaskNotes(task.id ?? "");

  const teams = useStoreTeams();
  const teamName = teams.find((team) => team.id === task.assigned_team_id)
    ?.name ?? <Trans>unknownTeamString</Trans>;

  const customTask = task as CustomTask;
  const plannedTaskParent = useWatchedTask(customTask.repeatable_task_id);
  const { previewHint: frequency } = useFormatScheduled(
    plannedTaskParent?.planned ?? customTask.planned ?? {}
  );

  const simpleCardMode = cardMode === "simple";
  const complexCardMode = cardMode === "complex";

  const isAddonTask =
    taskInfo.type === "Pick & Refill" || taskInfo.type === "Refill";

  if (!task) return null;

  return (
    <>
      {multiSelect && (
        <div className={styles["checkbox-wrapper"]}>
          <input
            type="checkbox"
            className={styles["checkbox"]}
            checked={isSelected}
            onChange={toggleThis}
          />
        </div>
      )}
      <div
        className={`${styles["task-card"]} ${
          multiSelect && styles["multi-select"]
        }`}
        onClick={() => {
          if (taskModal) {
            toggleModal({
              type: ModalType.TaskDetail,
              payload: { taskId: task.id },
            });
            trackerHelper.trackActivityTaskCardClick();
          } else if (!isSwiping) {
            push(`/task/${task.id}`);
          }
        }}
      >
        <div className={styles["top-wrapper"]}>
          <div className={styles["type"]}>
            {isOverdue(task) && (
              <span className={styles["overdue"]}>
                [<Trans>overdueString</Trans>]{" "}
              </span>
            )}
            {taskInfo.type}
          </div>
          {task.priority_flag && (
            <div className={styles["priority-wrapper"]}>
              <div
                className={`${styles["priority-dot"]} ${
                  isTaskCompleted && styles["completed"]
                }`}
              />
              <div
                className={`${styles["priority"]} ${
                  isTaskCompleted && styles["completed"]
                }`}
              >
                {!simpleCardMode && (
                  <>
                    {task.task_type === "PRODUCT_QUALITY" ? (
                      <Trans>safetyAlarmString</Trans>
                    ) : (
                      <Trans>priorityString</Trans>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <div className={styles["icon-wrapper"]}>
            {taskImages.length > 0 && (
              <SSRIcon paths={imageIcon} className={styles["icon"] as string} />
            )}
            {taskNotes.length > 0 && (
              <SSRIcon
                paths={commentIcon}
                className={styles["icon"] as string}
              />
            )}
          </div>
        </div>
        <div className={styles["image-wrapper"]}>
          {basicProduct.smallImage && complexCardMode && (
            <img
              alt=""
              height={44}
              data-testid="productImage"
              src={basicProduct.smallImage}
            />
          )}
          <div className={styles["title-wrapper"]}>
            <div className={styles["title"]}>{taskInfo.title}</div>
            {!simpleCardMode && (
              <>
                {isAddonTask ? (
                  <div className={styles["product-description"]}>
                    {productDescripion}
                  </div>
                ) : (
                  <div className={styles["subtitle"]}>{taskInfo.subtitle}</div>
                )}
              </>
            )}
            {frequency && !simpleCardMode && (
              <div className={styles["frequency-container"]}>
                <SSRIcon
                  className={`${styles["closed-icon"]} ${
                    styles["frequency-icon"]
                  } ${
                    (isTaskCompleted || isTaskClosed) &&
                    styles["frequency-completed"]
                  }`}
                  paths={arrowIcon}
                />
                <div
                  className={`${styles["frequency-text"]} ${
                    (isTaskCompleted || isTaskClosed) &&
                    styles["frequency-completed"]
                  }`}
                >
                  {frequency}
                </div>
              </div>
            )}
          </div>
        </div>
        {!simpleCardMode && isAddonTask && (
          <div className={styles["subtitle"]}>{taskInfo.subtitle}</div>
        )}
        {complexCardMode && (
          <div style={{ display: "flex" }}>
            <Creator task={task} />
            <CreatedAtDate createdAt={task.created_at} />
          </div>
        )}
        {(location.pathname === "/activity" ||
          location.search === "?active=activity") &&
          !isTaskClosed && (
            <div className={styles["assignee-wrapper"]}>
              <SSRIcon
                className={styles["assignee-icon"] as string}
                paths={peopleIcon}
              />
              {teamName}
            </div>
          )}
        {isTaskClosed && !location.pathname.includes("related") && (
          <div className={styles["closed-wrapper"]}>
            <div>
              <Trans>closedByUppercaseString</Trans>: {teamName}
            </div>
            <div className={styles["closed-reason"]}>
              <SSRIcon
                className={styles["closed-icon"] as string}
                paths={crossIcon}
              />
              <div>
                <Trans>{getClosedTaskReason(task.close_type as string)}</Trans>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
