import React, { useContext } from "react";
import type { InlineMessageVariant } from "@ingka/inline-message";
import InlineMessage from "@ingka/inline-message";
import Button from "@ingka/button";
import { ModalContext } from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";

interface TeamSelectionMessageProps {
  title: string;
  message: string;
  icon: () => any;
  variant: InlineMessageVariant;
  showAction: boolean;
  actionText?: string;
  actionLink?: string;
}

export const TeamSelectionMessage: React.FC<TeamSelectionMessageProps> = ({
  title,
  message,
  icon,
  actionText,
  variant,
  showAction,
}) => {
  const { toggleModal } = useContext(ModalContext);
  const handleSuperUsersClick = () => {
    toggleModal({ type: ModalType.SuperUsersList });
  };
  return (
    <InlineMessage
      title={title}
      body={message}
      ssrIcon={icon}
      actions={
        showAction
          ? [
              {
                text: (
                  <Button
                    type="secondary"
                    className="inline-message-button"
                    text={actionText || ""}
                  />
                ),
                onClick: () => handleSuperUsersClick(),
              },
            ]
          : []
      }
      variant={variant}
      className="inline-message-skapa"
    />
  );
};
