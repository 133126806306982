import React, { useState } from "react";
import { INTERVAL } from "../constants/clientTime";
import useCallInternal, {
  callInternalApi,
  GET_BIG_PRODUCT_IMAGE,
  getCachedInternalApi,
  NEARBY_PRODUCTS,
  PRODUCT_BASICS,
} from "../hooks/API/useCallInternal";
import {
  firstArrayWithContent,
  firstPresent,
} from "@coworker/functions/src/utils/array.helpers";
import { parseProduct as parseProductHelper } from "@coworker/functions/src/utils/products.helper";
import {
  readLocalProductCache,
  useFetchedBasicProduct,
  writeLocalProductCache,
} from "../hooks/useBasicProductsMap";
import { useStoreId } from "../core/auth/useLoggedInUser";
import {
  addSoftSpace,
  cleanupProductTitle,
  formatProductIdWithDots,
  formatWithCommas,
} from "@coworker/reusable";
import { useMyStore } from "../hooks/useMyStore";

/**
 * Caching for MFS data.
 *
 * key
 * [code, store_id]
 *
 * cached object
 * {
 *  key,
 *  notFound,
 *
 *  number,
 *  type (ART/SPR),
 *  title,
 *  kindAndColor,
 *  images,
 *  price
 *  product_type,
 *  product_color_description,
 *  imperialMeasurements,
 *  metricMeasurements,
 *  unitCode,
 * }
 */
const cachedProductData = {};
const smallProductImage = {};

// prevent AKAMAI from guessing wrongly about supported image formats
export function correctImageFormat(url) {
  if (!url?.split) return null;
  const [base, ...params] = url.split(/\?|\&/);
  const newParams = params
    .filter((part) => !part.startsWith("imformat=") && !part.startsWith("f="))
    .concat(["f=u", "imformat=generic"]);
  return base ? base + "?" + newParams.join("&") : "";
}

/**
 * @param {String} some_id Preferably full IKEA Product ID (ART12345678), but if not available, short_id can be made to work too.
 * @returns {String} Product image URL
 */
export function useBigProductImage(some_id) {
  const [short_id, type] = parseProduct(some_id);
  const previous = cachedProductData[short_id];
  const good = (url) => {
    const isBigImage = url?.match && url.match(/_[sS][45]/);
    return isBigImage ? url : "";
  };

  const found =
    good(pickImageMFS(previous?.Images, true)) ||
    good(pickImageMFS(previous?.images, true));

  const store = useStoreId();
  const [imageUrl, setImageUrl] = useState("");

  const callTasksServiceFlag = window.callTasksService;
  const primary_locale = useMyStore()?.configuration?.locale?.primary_locale;
  const blockRequest = !!found || !short_id || !store || callTasksServiceFlag;

  React.useEffect(() => {
    if (callTasksServiceFlag && primary_locale && short_id && !found) {
      callInternalApi(
        `products/big_product_image?short_id=${short_id}&locale=${primary_locale}`
      ).then((response) => setImageUrl(response));
    }
  }, [short_id, primary_locale, callTasksServiceFlag, found]);

  const { data } = useCallInternal(
    GET_BIG_PRODUCT_IMAGE,
    { store, short_id, type, store_id: store },
    { blockRequest, cacheTimeout: INTERVAL.HOUR }
  );

  const { url } = imageUrl;
  return callTasksServiceFlag ? url : found || data;
}

// Returns the best (big or not) image from an Images array from a MFS record
function pickImageMFS(images, big = false) {
  if (!images || !images.length) return null;
  let found;
  for (const imageSizes of images) {
    const { S1, S2, S3, S4, S5 } = imageSizes || {};
    if (big) found = (S3 || S4 || S5)?.Url;
    // The specific ordering of image sizes here are so that we get the best possible dimensions for the scrollable search hits list
    if (!found) found = (S2 || S3 || S4 || S1 || S5)?.Url;
    if (found) break;
  }
  // Fix issue with images from MFS sometimes breaking.
  return correctImageFormat(found);
}

/**
 * @param {String} some_id Preferably full IKEA Product ID (ART12345678), but if not available, short_id can be made to work too.
 * @param {Object} options
 * @param {String} options.image Url of already chosen image
 * @param {Object} options.task If we're showing an existing task we might have the image url cached in task.item.image_small
 * @returns {String} Product image URL
 */
export function useSmallProductImage(some_id, alreadyFoundImage) {
  // NOTE: For performance we will keep using images coming in from cachedProductData which comes from useMixedSearchProducts.
  const [short_id] = parseProduct(some_id);
  const previous = cachedProductData[short_id];
  const { basicProduct } = useFetchedBasicProduct(some_id);

  const found =
    smallProductImage[short_id] ||
    basicProduct?.smallImage ||
    pickImageMFS(previous?.Images, false) ||
    pickImageMFS(previous?.images, false) ||
    alreadyFoundImage;

  if (found) keepSmallImage(short_id, { image: found });

  return correctImageFormat(found);
}

function checkIfImperial(language) {
  return language?.includes && language.includes("US"); // es_US and en_US
}

/**
 * We might get product images from:
 * - MFS Search as Images = [{S1: "...", ... "S5": "..."}]
 * - SIK search mainImageUrl
 * - (other APIs like "communications" have images, slightly different, but that API is no longer used by us)
 */
export function keepSmallImage(code, { image, Images, images } = {}) {
  const [short_id] = parseProduct(code);
  const found = smallProductImage[short_id];
  return (smallProductImage[short_id] =
    found || image || pickImageMFS(Images || images, false) || images?.[0]);
}

// helper
export function cachedProductKeyAndData(code) {
  const [short_id] = parseProduct(code);
  const value = cachedProductData[short_id]
    ? Object.assign({}, cachedProductData[short_id])
    : null;
  return [short_id, value];
}

// helper for the parseSearchProduct.from... methods below
function keepProductData(data) {
  const [key] = cachedProductKeyAndData(data.key);

  // Keep also in localStorage cache.
  const [short_id, type] = parseProduct(data.key);
  if (short_id && type) {
    const basicProduct = {
      type,
      id: short_id,
      name: data.title,
      descriptives: [data.kindAndColor, data.measurements],
      descriptivesWithoutKindAndColor: data.descriptivesWithoutKindAndColor,
      smallImage: data.image,
      panumber: data.panumber || "",
    };
    writeLocalProductCache(short_id, basicProduct, false);
  }

  return (cachedProductData[key] = data);
}

function kindAndColorMFS(item) {
  const color = item.ColourDescription || item.Colour;
  return addSoftSpace(`${item.ProductType}${color ? `, ${color}` : ""}`);
  // \u200b is a zero-width space. Needed to help browser break long lines.
}

// Returns cached data.
export function getProductCachedData(code) {
  const [, data] = cachedProductKeyAndData(code);
  if (!data) return;
  return data;
}

export function useCachedBasicProduct(shortId) {
  const store = useStoreId();
  const [productBasics, setProductBasics] = React.useState();
  const primary_locale = useMyStore()?.configuration?.locale?.primary_locale;
  const callTasksService = window.callTasksService;

  React.useEffect(() => {
    if (callTasksService && shortId && store) {
      callInternalApi(
        `product_basics?shortId=${shortId}&locale=${primary_locale}`
      ).then((response) => {
        setProductBasics(response);
      });
    }
  }, [shortId, store, callTasksService, primary_locale]);

  const { data } = useCallInternal(
    PRODUCT_BASICS,
    { store, shortId, store_id: store, primary_locale: primary_locale },
    {
      blockRequest: !shortId || !store || callTasksService,
      cacheTimeout: INTERVAL.HOUR,
    }
  );
  return callTasksService ? productBasics : data;
}

const emptyList = [];

export function useBasicProductFromTask(
  task,
  { includeType, includeDescription }
) {
  const [short_id, cached] = cachedProductKeyAndData(task?.product_article_id);
  const localCache = readLocalProductCache(short_id);
  const shouldFetch = !!task && !localCache; // Cached fields still aren't reliably correct, so we still ignore the Firestore-stored fields.
  const data = useCachedBasicProduct(shouldFetch && short_id);
  const { name, descriptives = emptyList } = localCache || data || {};

  return React.useMemo(
    () => ({
      title: firstPresent([name, cached?.title]), // task?.item_name, // TODO: enable again after fixing incorrect task product info caching
      productType:
        includeType &&
        addSoftSpace(
          firstPresent([cached?.product_type, task?.item_type, descriptives[0]])
        ),
      productDescription:
        includeDescription &&
        formatWithCommas(
          firstArrayWithContent([
            descriptives,
            [task?.item_type, task?.item_color, task?.item?.measurements],
            [cached?.kindAndColor, cached?.measurements],
          ])
        ),
    }),
    [name, descriptives, task, cached, includeType, includeDescription]
  );
}

/**
 * Parses a MFS search result item and returns a product object
 * @param {Object} item
 * @returns {Object}
 */
function parseMFSItem(item, language) {
  const [, previous] = cachedProductKeyAndData(item.ItemId);
  return {
    key: `${item.ItemType}${item.ItemId}`,
    id: item.ItemId,
    number: formatProductIdWithDots(item.ItemId),
    type: item.ItemType,
    title: cleanupProductTitle(item.title || item.Name || previous?.title),
    image: keepSmallImage(item.ItemId, item),
    kindAndColor:
      // Prefer already cached kindAndColor if the new `item` lacks colour info (i.e. from retailitems endpoint)
      (!item.ColourDescription && previous?.kindAndColor) ||
      kindAndColorMFS(item),
    product_type: item.ProductType,
    product_color_description:
      // Prefer already cached if the new `item` lacks colour info (i.e. from retailitems endpoint)
      item.ColourDescription ||
      item.Colour ||
      previous?.product_color_description,
    Images: item.Images,
    price: item.price || returnPriceObject(item),
    unitCode: item.ItemUnitCode,
    measurements:
      item.measurements ||
      measurementsMFS(item, language) ||
      previous?.measurements,
    // LatestSalesDate is only present in search this is to prevent it from being removed
    // or have an invalid date
    latestSalesDate:
      (item.LatestSalesDate || previous?.latestSalesDate) &&
      new Date(item.LatestSalesDate || previous.latestSalesDate),
  };
}

/**
 * Parses a SIK search result item and returns a product object
 * @param {Object} item
 * @returns {Object}
 */
function parseSIKItem(item) {
  const [type, short_id] = item.id.startsWith("s")
    ? ["SPR", item.id.slice(1)]
    : ["ART", item.id];
  return {
    key: `${type}${short_id}`,
    number: formatProductIdWithDots(short_id),
    type,
    title: item.name,
    image: keepSmallImage(short_id, { image: item.mainImageUrl }),
    kindAndColor: kindAndColorSIK(item),
    descriptivesWithoutKindAndColor: formatLoaclName(item),
    product_type: item.typeName, // category-ish short text
    product_color_description: item.ColourDescription || item.Colour,
    price: {
      price: item.salesPrice.numeral,
      currency: item.salesPrice.currencyCode,
      priceSecondary: item.salesPriceSecondary?.numeral,
      currencySecondary: item.salesPriceSecondary?.currencyCode,
    },
    measurements: item.itemMeasureReferenceText,
  };
}

/**
 * Parses a MFS search result item and returns the measurements text
 * @param {Object} item
 * @returns {String}
 */
function measurementsMFS(item, language) {
  return checkIfImperial(language)
    ? item.ItemMeasureReferenceTextImperial
    : item.ItemMeasureReferenceTextMetric;
}

const parseAndKeep = (parser) => (_, language, items) =>
  (items || []).map((item) => keepProductData(parser(item, language)));

const parseOnly = (parser) => (_, language, items) =>
  (items || []).map((item) => parser(item, language));

// converts MFS and SIK search results into a rerender-friendly internal format, and stored (kept) in cache
export const parseSearchProduct = {
  fromMFS: parseOnly(parseMFSItem),
  fromSIK: parseAndKeep(parseSIKItem),
};

function kindAndColorSIK(item) {
  // extract product kind and color
  const colors = [];
  for (const { name } of item.colors) {
    if (name) colors.push(name);
  }
  return formatWithCommas([item.typeName, formatWithCommas(colors)]);
}

function formatLoaclName(item) {
  const localName = item.itemNameLocal?.split(" ");
  const [measurements, cm] = item.itemMeasureReferenceText?.split(" ");

  if (localName.includes(measurements)) {
    const found = localName.findIndex((element) => element === measurements);
    if (found !== -1) {
      localName.splice(found, 1, `${measurements} ${cm}`);
    }
    const nameIndex = localName.findIndex((element) => element === item.name);
    if (nameIndex !== -1) {
      localName.splice(nameIndex, 1);
    }
    const measurementIndex = localName.findIndex((element) => element === cm);
    if (nameIndex !== -1) {
      localName.splice(measurementIndex, 1);
    }
  }
  return localName.toString();
}

/**
 * Return price unit string.
 * @param {Number} [priceFactor = '']
 * @param {String} unit
 * @returns {String}
 */
const returnPriceUnit = (priceFactor = "", unit) => `/${priceFactor} ${unit}`;

/**
 * Return correct price object for item.
 * @param {Object} item
 * @param {Boolean} isImperial
 * @returns {Object}
 */
export function returnPriceObject(item, isImperial) {
  if (!item) return;
  /*
    Properties:
    - price
    - originalPrice
    - isFamilyPrice
    - newLowerPrice
    - isBreathTakingItem
    - priceUnit
    - currency
  */
  let price = "";
  let originalPrice = null;
  let isFamilyPrice = false;
  let newLowerPrice = false;
  let priceUnit = null;
  let currency = "";
  // get the normal price
  const normalPriceObj =
    item.RetailUnitPrices &&
    item.RetailUnitPrices.find(({ Type }) => Type === "NORMAL");
  price = normalPriceObj?.Value || null;
  currency = normalPriceObj?.Currency || null;

  if (
    normalPriceObj &&
    "PriceReasonCode" in normalPriceObj &&
    normalPriceObj.PriceReasonCode === "NEW_LOWER_PRICE"
  ) {
    newLowerPrice = true;
    originalPrice = normalPriceObj.OriginalValue;
  }
  // find out if item has family price. check if type is FAMILY
  const familyPriceObj =
    item.RetailUnitPrices &&
    item.RetailUnitPrices.find(({ Type }) => Type === "FAMILY");
  if (familyPriceObj) {
    isFamilyPrice = true;
    newLowerPrice = false;
    price = familyPriceObj?.Value;
    originalPrice = normalPriceObj?.Value;
  }
  // price unit check and get
  if ("UnitPriceFactor" in item) {
    const { UnitPriceFactor } = item;
    const unitText = isImperial
      ? UnitPriceFactor.UnitTextImperial
      : UnitPriceFactor.UnitTextMetric;
    let priceFactor = "";

    if (unitText) {
      priceUnit = returnPriceUnit(priceFactor, unitText);
    }

    if (
      "UnitPriceFactorMetric" in UnitPriceFactor ||
      "UnitPriceFactorImperial" in UnitPriceFactor
    ) {
      priceFactor = isImperial
        ? UnitPriceFactor.UnitPriceFactorImperial
        : UnitPriceFactor.UnitPriceFactorMetric;

      priceUnit = returnPriceUnit(priceFactor, unitText);
      // !!!! The below calculation to adjust the price for products with a declared AREA price
      // !!!! (like SEK / m²) has turned out to cause big issues with refills of carpet KOMPLEMENT (504.653.89 and more)
      // !!!! which should not be priced per sq.m. It also caused very very wrong numbers in Insights.
      // if (UnitPriceFactor.UnitPriceGroupCode === "AREA") {
      //   price = Number(price) / Number(priceFactor);
      //   priceUnit = returnPriceUnit("", unitText);
      // }
    } else {
      if (UnitPriceFactor.UnitPriceGroupCode === "MULTIPACK") {
        priceUnit = "";
      }
    }
  }

  const priceObj = {
    price,
    isFamilyPrice,
    newLowerPrice,
    currency,
  };
  if (originalPrice !== null) {
    priceObj.originalPrice = originalPrice;
  }

  if (priceUnit !== null && priceUnit !== "") {
    priceObj.priceUnit = priceUnit;
  }
  return priceObj?.price ? priceObj : null;
}

export function useNearbyProducts(location) {
  const store = useStoreId();
  const [nearbyProductsFromService, setNearbyProductsFromService] =
    React.useState({});
  const callTasksServiceFlag = window.callTasksService;
  const { data: nearbyProducts, loading } = useCallInternal(
    NEARBY_PRODUCTS,
    { store, location, store_id: store },
    {
      blockRequest: !(location && store) || callTasksServiceFlag,
      cacheTimeout: 30 * INTERVAL.MINUTE,
    }
  );

  React.useEffect(() => {
    if (callTasksServiceFlag && location) {
      callInternalApi(`nearby_products?location=${location}`).then((response) =>
        setNearbyProductsFromService(response)
      );
    }
  }, [location, callTasksServiceFlag]);

  return {
    nearbyProducts: callTasksServiceFlag
      ? nearbyProductsFromService?.data ?? []
      : nearbyProducts?.data ?? [],
    loading: callTasksServiceFlag ? false : loading,
  };
}

/**
 * parse product number
 * @param {string} string Anything resembling an IKEA product number, so ART12345678, 132.456.78, s12345678 and some more variants accepted.
 * @returns {[shortId:string, type:"ART"|"SPR"|""]}
 */
export function parseProduct(string = "") {
  const [shortId, type] = parseProductHelper(string);
  return [shortId, type || cachedProductData[shortId]?.type || ""];
}

export function useProductType(some_id, fallback) {
  return useFetchedBasicProduct(some_id)?.type || fallback || "";
}

export function usePIPHome(shortId) {
  const [response, setResponse] = React.useState({ loading: true });
  const primary_locale = useMyStore()?.configuration?.locale?.primary_locale;
  React.useEffect(() => {
    getCachedInternalApi(
      primary_locale && shortId
        ? `products/pip_home?short_id=${shortId}&locale=${primary_locale}`
        : "",
      setResponse,
      INTERVAL.MINUTE,
      true
    );
  }, [shortId, primary_locale]);
  return response;
}
